<template>
    <div class="page page-thank-you">
        <div class="page-container">
            <div class="container">

                <div class="order-status-icon">
                    <img
                        class="icon"
                        v-if="orderStatusIcon"
                        :src="orderStatusIcon"
                        alt="order status icon"
                    >
                </div>

                <h1 class="title text-center">{{ $t('pageThankYou.title') }}</h1>

                <p class="description text-center">{{ orderStatusDescription }}</p>

                <MainButton
                    class="mb-3"
                    v-if="orderState?.id === '1'"
                    btn-type="button"
                    color-mode="success"
                    :btn-text="$t('pageThankYou.payOrder')"
                    :is-loading="loaders.getNewLink"
                    @handle-click="payOder"
                />

                <MainButton
                    class="mb-3"
                    v-if="orderState?.id === '2'"
                    btn-type="button"
                    color-mode="primary"
                    :btn-text="$t('pageThankYou.showPosition')"
                    @handle-click="showPositionDetail"
                />
            </div>
        </div>
    </div>
</template>

<script>
import orderService from "../../functions/services/orderService";
import MainButton from "../../components/elements/MainButton.vue";
import successIcon from "../../assets/icons/success.svg"
import failedIcon from "../../assets/icons/failed.svg"
import positionService from "@/functions/services/positionService";

export default {
    name: "PageThankYou",
    mixins: [ orderService, positionService ],
    components: {
        MainButton
    },
    metaInfo() {
        return {
            title: 'Ďakujeme za vašu objednávku video profilu',
        }
    },
    data() {
        return {
            successIcon,
            failedIcon,

            orderId: '',
            orderData: {},

            loaders: {
                getNewLink: false,
            }
        };
    },
    mounted() {
        this.orderId = this.$route?.params?.order_id
        if (this.orderId) this.getOrderDetail()
    },
    methods: {
        getOrderDetail() {
            this.axiosGetOrderDetail(this.orderId).then(res => {
                // console.log('axiosGetOrderDetail', res);
                this.orderData = res.data.data
            }, () => {
                this.$router.replace({ name: 'PageHome' })
            })
        },
        payOder() {
            this.loaders.getNewLink = true
            this.axiosGetGeneratePaymentGatewayLink(this.orderId).then(res => {
                // console.log('axiosPostPurchaseProduct', res);
                const data = res.data.data

                this.$nextTick(() => {
                    // redirect to payment gateway
                    window.open(data?.invoice?.payment_links, '_self')

                    // this.$router.push({ name: 'PageThankYou', params: { order_id: data.id || 'no-order-id' } })
                    // this.$root.$refs.ref_toast.showToast(res.data.message, 'toast-success')
                })
            }, e => {
                // console.error(e);
                const message = e?.response?.data?.message
                this.$root.$refs.ref_toast.showToast(message, 'toast-danger')
            }).finally(() => {
                setTimeout(() => {
                    this.loaders.getNewLink = false
                })
            })
        },
        showPositionDetail() {
            this.$router.push({ name: 'PagePositionDetail', params: { slug: this.orderData?.position?.slug } })
        }
    },
    computed: {
        orderState() {
            return this.orderData?.status
        },
        orderStatusDescription() {
            switch (this.orderState?.id) {
                case '1':
                    return this.$t('pageThankYou.descriptionPayment')
                case '2':
                    return this.$t('pageThankYou.descriptionSuccessfully')
                case '3':
                    return this.$t('pageThankYou.descriptionUnsuccessfully')
                default:
                    return ''
            }
        },
        orderStatusIcon() {
            switch (this.orderState?.id) {
                case '1':
                case '2':
                    return this.successIcon
                case '3':
                    return this.failedIcon
                default:
                    return ''
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.page-thank-you::v-deep(.page-container) {
    margin: 0 0 30px;

    .order-status-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;

        .icon {
            width: 100%;
            max-width: 150px;
            height: 100%;
            max-height: 150px;
            aspect-ratio: 1;
        }
    }

    .title {
        margin-bottom: 20px;
    }

    .description {
        margin-bottom: 50px;
        white-space: pre-line;
    }

    @media (min-width: 1200px) {
        margin: 50px 0 100px;
    }
}
</style>